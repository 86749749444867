import React from "react"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: "100%",
    height: "auto",
  },
}))

const ResponsiveImage = ({ alt, src }) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <img className={classes.image} src={src} alt={alt} />
    </React.Fragment>
  )
}

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
}

export default ResponsiveImage
