import React from "react"

import {
  ThemeProvider,
  CssBaseline,
  useTheme,
  Box,
  Typography,
  useMediaQuery,
} from "@material-ui/core"

import theme from "../theme"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GeneralSection from "../components/general-section"
import QuoteHero from "../components/quote-hero"
import MainContent from "../components/main-content"
import CoButton from "../components/co-button"
import Strong from "../components/strong"
import ResponsiveImage from "../components/responsive-image"
import LocationSection from "../components/location-section"
import ContactSection from "../components/contact-section"
import CoLink from "../components/co-link"
import CoExternalLink from "../components/co-external-link"
import DialogClosedBooking from "../components/dialog-closed-booking"

import payments from "../images/payments.png"
import ClinicSlider from "../components/clinic-slider"

const ServicesPage = () => {
  const theme = useTheme()
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = React.useState(false)
  const data = {
    headline: "LOOKING FOR A MASSAGE THERAPIST?",
    headlineMaxWidth: "1077px",
    tagline:
      "Midori Ishimaru can do both relaxing massage and massages that can help with injuries. Please contact us to ensure we are on the same page.",
    taglineMaxWidth: "680px",
    primaryBtnLabel: "BOOK ONLINE",
    primaryHref: theme.data.janeApp,
    secondaryBtnLabel: "KNOW MORE",
    secondaryHref: "/about",
    index: false,
    heroVh: "70vh",
  }

  const onOpenDialog = () => {
    setOpen(true)
  }

  const onCloseDialog = () => {
    setOpen(false)
  }

  return (
    <Layout data={data} onOpenDialog={onOpenDialog}>
      <SEO
        title="Services"
        description="Aside from doing a relaxing full body massage, Midori’s specialties are checking all the joints, the body alignment, and releasing of body tension. She can also do pre-natal massage, post-natal massage, chronic pains, circulatory conditions, stress, anxiety, depression, headaches, and sports or accident related injuries (depending on the severity)."
      />
      <GeneralSection mb="10rem">
        <QuoteHero
          srcDesktop="quote-desktop-sister.jpg"
          alt="When I was small, I use to give my older sister head and neck massage to ease her headaches."
        />
      </GeneralSection>
      <GeneralSection>
        <MainContent title="Appointments">
          <Box>
            <Box textAlign="justify" my="2rem">
              <Box mb="3rem">
                <Typography paragraph={true}>
                  You can book <Strong>45 minute</Strong> appointments or{" "}
                  <Strong>60 minutes</Strong> (1 hr) appointments. Green Stone
                  Wellness Clinic uses JaneApp so client’s can book massages
                  online and in advance. Make sure you arrive before your
                  appointment starts.
                </Typography>
              </Box>
              {!matchesSm && (
                <Box textAlign="center">
                  <CoButton
                    href={theme.data.janeApp}
                    type="primary"
                    onClick={() => onOpenDialog()}
                  >
                    BOOK ONLINE
                  </CoButton>
                </Box>
              )}
            </Box>
          </Box>
        </MainContent>
        <MainContent title="Medical Intake Form" mb="3rem">
          <Box mb="3rem">
            <Typography>
              The Medical Intake Form is for first time patients who just booked
              an appointment with Midori at Green Stone Wellness Clinic. Please
              fill this form through your JaneApp account before your
              appointments. This will ensure that Midori will know what
              treatment plan she will do on your treatment day. If you wish to
              discuss more please use our{" "}
              <CoLink to="/services/#contact">CONTACT FORM</CoLink>.
            </Typography>
          </Box>
        </MainContent>
      </GeneralSection>
      <GeneralSection>
        <ClinicSlider />
      </GeneralSection>
      <GeneralSection>
        <MainContent title="Specialties">
          <Typography>
            Aside from doing a relaxing full body massage, Midori’s specialties
            are checking all the joints, the body alignment, and releasing of
            body tension. She can also do pre-natal massage, post-natal massage,
            chronic pains, circulatory conditions, stress, anxiety, depression,
            headaches, whiplash, hemiplegia, thoracic outlet syndrome, injuries
            caused by sports or accident related injuries (depending on the
            severity). Please contact us to make sure what you need, you can use
            our <CoLink to="/services/#contact">CONTACT FORM</CoLink>.
          </Typography>
        </MainContent>
        <MainContent title="Cancellation Policy">
          <Typography paragraph={true}>
            We understand that it’s unavoidable when there are emergencies on
            the day. It is also hard to call someone last minute to fill in an
            appointment spot when a patient cancels on the day.
          </Typography>
          <Typography>
            Please give Midori exactly <Strong>1 day</Strong> (24 hrs) before
            your appointment to cancel, so she call someone to fill in your
            spot. Patients can also help by asking someone ahead of time to take
            on your behalf and letting us know. You can also see the{" "}
            <CoExternalLink
              href={theme.data.janeApp}
              onClick={() => onOpenDialog()}
            >
              ONLINE BOOKING
            </CoExternalLink>{" "}
            for spots that might have opened up too!
          </Typography>
        </MainContent>
        <MainContent title="Direct Billing">
          <Box mb="3rem">
            <Typography paragraph={true}>
              Our clinic does online direct billing to most major insurance
              companies. Please contact us to let us know which insurance
              company you are with. Also, please be advised that all policies
              are different for each individual. Patients will have to contact
              their insurance to know if they cover massage and how much each
              individual is covered per visit.
            </Typography>
            <Typography>
              For ICBC and WorkSafe (WCB) claims, our clinic unfortunately does
              not offer any direct billing with these establishments. However, a
              patient with an ICBC or WCB claim can still be treated. In order
              for this to happen patients must pay upfront for the treatments
              given on the day and the clinic will provide the official receipts
              needed for proof of treatment for their ICBC or WCB claim. These
              official receipts can be reprinted, emailed, or made into a
              statement of account for whenever patients need them.
            </Typography>
          </Box>
          <Box textAlign="center" mb={4}>
            <ResponsiveImage src={payments} alt="MasterCard, Visa, Interact" />
          </Box>
        </MainContent>
      </GeneralSection>
      <ContactSection />
      <LocationSection />
      <DialogClosedBooking open={open} onCloseDialog={onCloseDialog} />
    </Layout>
  )
}

const StyledServicesPage = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ServicesPage />
  </ThemeProvider>
)

export default StyledServicesPage
