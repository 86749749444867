import React from "react"
import Carousel from "nuka-carousel"

import Image from "./image"

const ClinicSlider = () => {
  const settings = {
    autoplay: true,
    pauseOnHover: true,
    wrapAround: true,
    renderCenterLeftControls: false,
    renderCenterRightControls: false,
    renderTopCenterControls: false,
  }

  return (
    <Carousel {...settings}>
      <Image imgName="massage-place.jpg" alt="Massage office" />
      <Image imgName="stuff.jpg" alt="Decors in office" />
      <Image imgName="next-gen-office.jpg" alt="Next Gen Office" />
      <Image imgName="parking-stall.jpg" alt="Parking stall" />
      <Image imgName="front.jpg" alt="Front of building" />
    </Carousel>
  )
}

export default ClinicSlider
