import React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  strong: {
    fontWeight: "700",
  },
}))

const Strong = ({ children }) => {
  const classes = useStyles()

  return <strong className={classes.strong}>{children}</strong>
}

export default Strong
