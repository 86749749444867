import React from "react"
import PropTypes from "prop-types"

import { Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  link: {
    fontWeight: "700",
    cursor: "pointer",
  },
})

const CoExternalLink = props => {
  const classes = useStyles()
  const { children, ...rest } = props

  return (
    <Link className={classes.link} {...rest}>
      {children}
    </Link>
  )
}

CoExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CoExternalLink
